import React from 'react'
import PropTypes from 'prop-types'
import { getAccounts, getNetWorthAccounts } from './queries'
import Selector, { SelectorItem } from './Selector'
import { createAccount } from '../Account/AccountForm/persistence'

type AccountTypes = 'all' | 'net_worth'

export default function AccountSelector({
  accountId,
  accountTypes = 'all',
  onChange,
  onKeyDown,
  className,
}: {
  accountId: string | null
  accountTypes: AccountTypes
  onChange: (c: string | null) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  className?: string
}) {
  const queryFn = (): Promise<SelectorItem[]> => {
    switch (accountTypes) {
      case 'all':
        return getAccounts('all').then((accounts) =>
          accounts.map(({ name, id, hidden }) => ({
            label: name,
            value: id,
            hidden,
          })),
        )
      case 'net_worth':
        return getNetWorthAccounts().then((accounts) =>
          accounts.map(({ name, id, hidden }) => ({
            label: name,
            value: id,
            hidden,
          })),
        )
      default:
        return Promise.resolve([])
    }
  }

  const handleCreateAccount = async (name: string) => {
    await createAccount({ name, type: 'EXPENSE' })
  }

  return (
    <Selector
      value={accountId}
      queryKey={`accounts-${accountTypes}`}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={className}
      queryFn={queryFn}
      onNewValue={handleCreateAccount}
    />
  )
}

AccountSelector.propTypes = {
  accountId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
