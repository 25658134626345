import React from 'react'
import PropTypes from 'prop-types'
import { getPayeeNames } from './queries'
import Selector from './Selector'

export default function PayeeSelector({
  payee,
  onChange,
  onKeyDown,
  className,
}: {
  payee: string | null
  onChange: (c: string | null) => void
  onKeyDown: (event: React.KeyboardEvent) => void
  className?: string
}) {
  const getPayees = async () =>
    (await getPayeeNames()).map((name: string) => ({
      label: name,
      value: name,
      hidden: false,
    }))

  const handleChange = (newPayee: string | null) => {
    onChange(newPayee)
  }

  return (
    <Selector
      value={payee}
      queryKey="payees"
      onChange={handleChange}
      onKeyDown={onKeyDown}
      className={className}
      queryFn={getPayees}
      onNewValue={async (p) => {
        // eslint-disable-next-line no-console
        console.log('createPayee', p)
      }}
    />
  )
}

PayeeSelector.propTypes = {
  payee: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
