export const formatDateToDisplay = (dateStr: string): string => {
  let date = new Date(dateStr)
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() + userTimezoneOffset)

  return date.toLocaleDateString(navigator.languages[0], {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
}

export const dateYMDToUTC = (date: Date): Date => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() + userTimezoneOffset)
}

export const formatCurrency = (amount: number): string =>
  amount.toLocaleString('en-US')

export const moneyInMajors = (amount: number): number =>
  amount ? amount / 100 : 0

export const moneyInCents = (amount: number): number => Math.trunc(amount * 100)

export const formatPercent = (percent: number, decimals: number): string =>
  `${(percent * 100).toFixed(decimals)}%`

export const formatDateToYMD = (date: Date): string =>
  date.toISOString().split('T')[0]

export const todayAsString = (): string => {
  const now = new Date()
  const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  return formatDateToYMD(todayDate)
}

export const incrementDate = (dateStr: string, increment: number): string => {
  const date = new Date(dateStr)
  date.setDate(date.getDate() + increment)
  return formatDateToYMD(date)
}

export const generateRandomUUID = (): string => window.crypto.randomUUID()

export const getMonthName = (month: number): string =>
  new Date(0, month - 1).toLocaleString(navigator.languages[0], {
    month: 'long',
  })
