import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

import { getBills } from './persistence'
import { Bill } from '../types'
import MoneyAmount from '../MoneyAmount'
import ErrorMessage from '../ErrorMessage'
import { getMonthName } from '../../util'
import FormattedDate from '../FormattedDate'

export default function BillsPage() {
  const { data: bills, error } = useQuery({
    queryKey: ['bills'],
    queryFn: () => getBills(),
  })

  const frequencyText = (bill: Bill) => {
    switch (bill.frequency) {
      case 'MONTHLY':
        return `Monthly on day ${bill.day}`
      case 'YEARLY':
        return `Yearly on ${bill.month ? getMonthName(bill.month) : '?'} ${bill.day}`
      default:
        return 'Unknown'
    }
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <div className="p-1">
      <h1 className="text-lg">Bills</h1>

      <ul>
        <li className="flex border-b-gray-400 border-b p-1">
          <div className="px-2 font-bold w-2/12">Payee</div>
          <div className="px-2 font-bold w-2/12">Account</div>
          <div className="px-2 font-bold w-2/12">Description</div>
          <div className="px-2 font-bold w-28 text-center">Amount</div>
          <div className="px-2 font-bold w-2/12">Frequency</div>
          <div className="px-2 font-bold w-2/12">Next Date</div>
        </li>

        {bills?.map((bill) => (
          <Link to={`/bills/${bill.id}/edit`} key={bill.id}>
            <li key={bill.id} className="flex border-b-gray-400 border-b p-1">
              <div className="px-2 w-2/12">{bill.payee}</div>
              <div className="px-2 w-2/12">{bill.accountName}</div>
              <div className="px-2 w-2/12">{bill.description}</div>
              <div className="px-2 w-28 text-right">
                {bill.autoAmount ? (
                  'Auto'
                ) : (
                  <MoneyAmount amountInCents={bill.amount} color />
                )}
              </div>
              <div className="px-2 w-2/12">
                {frequencyText(bill)}
                {bill.autoInsert && ' (auto)'}
              </div>
              <div className="px-2 w-2/12">
                <FormattedDate date={bill.nextDate} />
              </div>
            </li>
          </Link>
        ))}
      </ul>

      <div className="mt-4">
        <Link to="/bills/new" className="button button-default">
          Add Bill
        </Link>
      </div>
    </div>
  )
}
